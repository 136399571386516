<template>
    <div>
      <div class="choix-date mt-5 mb-12 d-flex align-start">
        <div class="mr-12">
          <HistoriqueCmdRemote :periode="periode"/>
        </div>
        
        <div class="ml-12 d-flex justify-space-around align-center flex-wrap">
          <v-date-picker class="" v-show="showFromPicker" v-model="periode.from_picker" locale="fr-FR"></v-date-picker>
          <v-date-picker class="" v-show="showToPicker" v-model="periode.to_picker" locale="fr-FR"></v-date-picker>
          <div class="">
            <v-btn rounded="" class="red" dark="" @click="fetchOrdersBetween">Rechercher</v-btn>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import HistoriqueCmdRemote from '../components/HistoriqueCmdRemote'
  import {mapActions, mapGetters} from 'vuex'
  export default {
    props: {cmdType: {default: 1}},
    components: {
        HistoriqueCmdRemote
    },
    data: () => ({
      periode: {
        from_picker: new Date().toISOString().substr(0, 10),
        to_picker: new Date().toISOString().substr(0, 10),
      },
      showFromPicker: true,
      showToPicker: true,
    }),
    methods: {
      ...mapActions(['fetchRemoteOrders']),
      fetchOrdersBetween() {
        console.log('in fetchOrdersBetween')

        console.log({min: new Date(this.periode.from_picker).getTime(), max: new Date(this.periode.to_picker).setHours(23)})
        this.fetchRemoteOrders({min: new Date(this.periode.from_picker).getTime(), max: new Date(this.periode.to_picker).setHours(23)})
      }
    },
    computed: {
      ...mapGetters(['isAdminConnected'])
    },
    created() {
      console.log('created commandes, cmdType ->', this.cmdType)
    },
    watch: {
      /*from_picker(maj) {
        console.log(Date.parse(maj))
        console.log(maj)
      },
      to_picker(maj) {
        console.log(Date.parse(maj))
      }*/
    }
  
  }
  </script>
  
  <style scoped>
    .pos-rel {
      position: relative;
    }
    .pos-abs {
      position: absolute;
    }
  </style>