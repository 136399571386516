import { render, staticRenderFns } from "./CommandesRemote.vue?vue&type=template&id=f1331a26&scoped=true"
import script from "./CommandesRemote.vue?vue&type=script&lang=js"
export * from "./CommandesRemote.vue?vue&type=script&lang=js"
import style0 from "./CommandesRemote.vue?vue&type=style&index=0&id=f1331a26&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f1331a26",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
installComponents(component, {VBtn,VDatePicker})
