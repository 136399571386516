<template>
  <div class="container">
    <v-card
      v-for="(order, i) in remoteOrders"
      :key="i"
      class="elevation-2 d-flex justify-space-around align-center mb-3"
    >
      <div class="ml-2" style="width: 200px; " >
        <div class="caption captitalize"> {{ dateFromTimestam(order.timestamp * 1000) }} </div>
        <div class="caption captitalize">{{ order.comment }}</div>
        <div class="caption captitalize">{{ order.client ? order.client.society : '' }}</div>
        <div class="caption captitalize">{{ Math.round(order.total*100)/100 }} €</div>
      </div>
      <div class="mr-2">
        <v-btn rounded class="red " dark @click="passerCommande(order)"><v-icon>mdi-cart-arrow-up</v-icon></v-btn>
      </div>
        
    </v-card>
  </div>
</template>
<script>
  import { mapGetters, mapActions, mapMutations } from "vuex";
  import axios from 'axios'
  export default {
    props: ["periode"],
    data: () => ({
      orderItems: null,
      client: null,
    }),
    computed: {
      ...mapGetters(["apiURL", "remoteOrders", "port", 'isAdminConnected', 'globalCmdType']),
    },
    components: {
    },
    watch: {
  
    },
    methods: {
      dateFromTimestam(timestamp) {
        var dateFromT = new Date(timestamp);
        var options = {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
        };
        var date = dateFromT.toLocaleDateString("fr-FR", options);
        return date;
      },
      async passerCommande(order) {
        try {
            let orderRes = await axios.get(`${this.apiURL}/tunnelToAlif/alifOrderItems/${order.id}`)
            console.log(orderRes.data)
            this.clearProducts()
            //this.products = orderRes.data.LocalOrderItems || []
            for(let orderItem of orderRes.data.orderItems) {
            //console.log(orderItem)
                orderItem.product.prixA = orderItem.productCurrentPrice
                orderItem.product.uniteDeVente = orderItem.quantity
                this.addProductToProducts(orderItem.product)
            }
            this.setClient(order.comment || order.client?.society)
            this.$router.push({
                name: 'Facturation',
                query: {...this.$route.query, cmdType: this.globalCmdType}
            })
        } catch(err) {
            console.log(err)
            this.addMessageAxios({message: err.response.data, isError: true})
        }
      },
      ...mapActions(["setOrderVu", 'deleteOrder']),
      ...mapMutations(['clearProducts', 'addProductToProducts', 'setClient']),
    },
  };
</script>

<style scoped>
.captitalize {
text-transform: uppercase;
}
.container {
/*width: 550px;*/
}
</style>